import {LocalStorageService} from './local-storage.service'
import {BehaviorSubject, catchError, map, NEVER, Observable, of, switchMap} from 'rxjs'
import {environment} from '../../environments/environment'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {BorgoLoginResult} from './borgo-login-result'
import {inject} from '@angular/core'
import {Router} from '@angular/router'

export class RequestService {

  /**
   * Keep this public so that others can react when there are
   * a new valid token to work with.
   */
  public token$ = new BehaviorSubject<BorgoLoginResult | null>(null)

  protected borgoAccessToken: BorgoLoginResult = new BorgoLoginResult()

  protected router = inject(Router)
  protected http = inject(HttpClient)

  private borgoTokenStorage: LocalStorageService<BorgoLoginResult | null>

  constructor(tokenName: string) {
    this.borgoTokenStorage = new LocalStorageService<BorgoLoginResult | null>(tokenName, this.token$)
    this.checkBorgoToken()
  }

  /**
   * Makes sure we are logged in when someone wants to use us.
   */
  public login(): Observable<BorgoLoginResult> {
    const url = `${environment.borgoApiUrl}/login`
    return this.http.get<BorgoLoginResult>(url).pipe(
      catchError(() => {
        this.reset()
        return NEVER
      }),
      map((token) => {
        this.token$.next(token)
        this.borgoAccessToken = token
        return token
      })
    )
  }

  /**
   * Checks auth and return the type T using GET
   * @param path - Exactly what you want, _including_ the leading / e.g. /users
   */
  protected getBorgo<T>(path: string): Observable<T> {
    const url = `${environment.borgoApiUrl}${path}`
    return this.checkLogin().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({'X-SPB-Borgo-Token': token})
        return this.http.get<T>(`${url}`, {headers}).pipe()
      })
    )
  }

  /**
   * Delete something with Borgo
   * @param path - Exactly what you want, _including_ the leading / e.g. /users
   */
  protected deleteBorgo(path: string): Observable<void> {
    const url = `${environment.borgoApiUrl}${path}`
    return this.checkLogin().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({'X-SPB-Borgo-Token': token})
        return this.http.delete<void>(`${url}`, {headers}).pipe()
      })
    )
  }

  /**
   * Checks auth and return the type T using PUT sending the data D
   * @param path - Exactly what you want, _including_ the leading / e.g. /users
   * @param data - Anything you can send as a JSON payload
   * @private
   */
  protected putBorgo<T, D>(path: string, data: D): Observable<T> {
    const url = `${environment.borgoApiUrl}${path}`
    return this.checkLogin().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({'X-SPB-Borgo-Token': token})
        return this.http.put<T>(`${url}`, data, {headers}).pipe()
      })
    )
  }

  protected refresh(): Observable<BorgoLoginResult> {
    return this.login()
  }

  public reset(): void {
    this.borgoTokenStorage.clear()
    this.borgoAccessToken = {token: '', refreshToken: '', expires: Date.now() - 1001} as any
    this.token$.next(null)
  }

  /**
   * If we have a stored token we pick it up.
   */
  protected checkBorgoToken(): void {
    this.borgoAccessToken = this.borgoTokenStorage.get() || new BorgoLoginResult()
    // If token still valid send it to our token$
    if (this.borgoAccessToken.expires - 1000 > Date.now()) {
      this.token$.next(this.borgoAccessToken)
    }
  }

  protected checkLogin(): Observable<string> {
    // If the token has more than one second to live, just use the
    // existing token.
    if (this.borgoAccessToken.expires - 1000 > Date.now()) {
      return of(this.borgoAccessToken.token)
    }

    // Otherwise, create a new one
    return this.refresh().pipe(
      map(result => {
        this.borgoAccessToken = result
        this.token$.next(result)
        return result.token
      })
    )
  }
}
